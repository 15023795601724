import React, { useEffect, useState } from "react";
import { Button, FormControl, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'

import GenericTemplate from "../templates/GenericTemplate";
import { getApi, execRefresh } from "../tools/api/Api";

const DIAGNOSIS_GUIDELINE = [
  'no', 'no', 'no', 'no', 'no',
  'no', 'no', 'no', 'yes', 'yes',
  'yes', 'yes',
  'yes', 'yes', 'yes',
  'no', 'yes',
  'yes', 'no', 'yes',
  'yes', 'yes', 'yes', 'yes', 'yes'];

const Diagnosis: React.FC = (props: any) => {
  const [token, setToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');

  const [userData, setUserData] = useState(['']);
  const [userNameToId, setUserNameToId] = useState<any>([]);
  const [userId, setUserId] = useState('');
  const [csvUserData, setCsvUserData] = useState('');

  const [telemetry, setTelemetry] = useState<any>([]);
  const [questions, setQuestions] = useState<any>([]);
  const [diagnosisResult, setDiagnosisResult] = useState<any>([]);
  const [noResult, setNoResult] = useState(false);

  const changeTokenState = (token: string, refreshToken: string, func?: any) => {
    setToken(token);
    setRefreshToken(refreshToken);
    if (func) func(token);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) setToken(token);
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) setRefreshToken(refreshToken);
  }, []);

  useEffect(() => {
    if (!(token && refreshToken)) {
      return;
    }

    getApi('tenant/devices?limit=1000', token)
    .then(result => {
      const userData: any = result.data['data']
      setUserData(userData);
      userData.forEach((data: any) => {
        const temp: any = userNameToId;
        temp[data.name] = data.id.id;
        setUserNameToId(temp);
      })

      getApi('tenant/assets?type=diagnose&limit=1', token)
      .then((result: any) => {
        getApi(`plugins/telemetry/ASSET/${result.data.data[0].id.id}/values/attributes`, token)
        .then((result: any) => {
          Object.entries(result.data).forEach((data: any) => {
            const key = 'd' + data[1].key;
            const temp: any = questions;
            temp[key] = data[1].value;
            setQuestions(temp);
          })
        })
      }) 
    })
    .catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            execRefresh(refreshToken, changeTokenState, props);
            break
        };
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, refreshToken]);

  const getTelemetry = (refreshedToken?: string) => {
    if (!userId) {
      return;
    }

    getApi(`plugins/telemetry/DEVICE/${userId}/values/timeseries`, refreshedToken || token)
    .then(result => {
      const telemetry = Object.entries(result.data)
      .filter((data: any) => { 
        return new RegExp(/(d[0-9]+)/).test(data[0])
      })
      .map((data: any) => {
        const ts = new Date(data[1][0].ts);
        const date = ts.getFullYear() + '-' + (ts.getMonth() + 1) + '-' + ts.getDate()
        return { question: data[0], answer: data[1][0].value, ts: date }
      })

      if (telemetry.length === 0) {
        setNoResult(true);
      } else {
        setNoResult(false);
      }

      const sortedResult = Object.keys(telemetry)
      .map((key: any) => { return telemetry[key] })
      .sort((a, b) => { return (Number(a.question.slice(1)) < Number(b.question.slice(1))) ? -1 : 1 })
      
      setTelemetry(sortedResult);

      const diagnosisResult = sortedResult.map((data: any, index: number) => {
        if (data.answer === DIAGNOSIS_GUIDELINE[index]) {
          return 1
        } else {
          return 0
        }
      });

      setDiagnosisResult(diagnosisResult);
    })
    .catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            execRefresh(refreshToken, changeTokenState, props, getTelemetry);
            break
        };
      }
    })
  };

  const createCsvData = (refreshedToken?: string) => {
    Object.entries(userNameToId).forEach((data: any) => {
      const userName = data[0];

      getApi(`plugins/telemetry/DEVICE/${data[1]}/values/timeseries`, refreshedToken || token)
      .then(result => {
        const telemetry = Object.entries(result.data)
        .filter((data: any) => { 
          return new RegExp(/(d[0-9]+)/).test(data[0])
        })
        .map((data: any) => {
          return { question: data[0], answer: data[1][0].value === 'yes' ? 'はい' : 'いいえ', ts: data[1][0].ts }
        })

        const date = telemetry.length > 0
                   ? new Date(telemetry[0].ts).getFullYear() + '-' + (new Date(telemetry[0].ts).getMonth() + 1) + '-' + new Date(telemetry[0].ts).getDate()
                   : ''
        
        const sortedResult = Object.keys(telemetry)
        .map((key: any) => { return telemetry[key] })
        .sort((a, b) => { return (Number(a.question.slice(1)) < Number(b.question.slice(1))) ? -1 : 1 })

        const userData = userName + ',' + date + ',' + sortedResult.map(data => { return data.answer }).join(',') + '\n';

        setCsvUserData((data: any) => {
          return data + userData;
        });
      })
      .catch(err => {
        if (err.response) {
          switch (err.response.status) {
            case 401:
              execRefresh(refreshToken, changeTokenState, props, createCsvData);
              break
          };
        }
      })
    })
  };

  useEffect(() => {
    if (csvUserData && csvUserData.split('\n').length === Object.keys(userNameToId).length + 1) {
      const filename = "diagnosis_records.csv";
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const header = 'ユーザー名,回答日,Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,Q10,Q11,Q12,Q13,Q14,Q15,Q16,Q17,Q18,Q19,Q20,Q21,Q22,Q23,Q24,Q25\n'
  
      const link = document.createElement("a");
      link.download = filename;
      link.href = URL.createObjectURL(new Blob([bom, header + csvUserData], { type: "text/csv" }));
      link.dataset.downloadurl = ["text/csv", link.download, link.href].join(":");
      link.click();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvUserData]);

  const diagnosisResultTable = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>項目</TableCell>
            <TableCell>チェック数</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>1-5</TableCell>
            <TableCell>日常生活上の不便</TableCell>
            <TableCell>
              {diagnosisResult.slice(0, 5).reduce((prev: number, cur: number) => {
                return prev + cur
              }, 0)}/5
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>6-10</TableCell>
            <TableCell>運動機能低下</TableCell>
            <TableCell>
              {diagnosisResult.slice(5, 10).reduce((prev: number, cur: number) => {
                return prev + cur
              }, 0)}/5
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>11,12</TableCell>
            <TableCell>栄養不足</TableCell>
            <TableCell>
              {diagnosisResult.slice(10, 12).reduce((prev: number, cur: number) => {
                return prev + cur
              }, 0)}/2
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>13-15</TableCell>
            <TableCell>口の機能低下</TableCell>
            <TableCell>
              {diagnosisResult.slice(12, 15).reduce((prev: number, cur: number) => {
                return prev + cur
              }, 0)}/3
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>16,17</TableCell>
            <TableCell>外出機会の減少</TableCell>
            <TableCell>
              {diagnosisResult.slice(15, 17).reduce((prev: number, cur: number) => {
                return prev + cur
              }, 0)}/2
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>18-20</TableCell>
            <TableCell>もの忘れ</TableCell>
            <TableCell>
              {diagnosisResult.slice(17, 20).reduce((prev: number, cur: number) => {
                return prev + cur
              }, 0)}/3
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>21-25</TableCell>
            <TableCell>落ち込み</TableCell>
            <TableCell>
              {diagnosisResult.slice(20, 25).reduce((prev: number, cur: number) => {
                return prev + cur
              }, 0)}/5
            </TableCell>
          </TableRow>
        </TableBody>  
      </Table>
    </TableContainer>
  );

  const resultTable = (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>質問</TableCell>
            <TableCell>回答</TableCell>
            <TableCell>回答日</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {telemetry.map((row: any, index: number) => (
            <TableRow key={row.question}>
              <TableCell>{row.question.slice(1)}</TableCell>
              <TableCell component="th" scope="row">
                {questions[row.question]}
              </TableCell>
              <TableCell style={{color: row.answer === DIAGNOSIS_GUIDELINE[index] ? 'red': 'black'}}>
                {row.answer === 'yes' ? 'はい' : 'いいえ'}
              </TableCell>
              <TableCell>
                {row.ts}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>  
      </Table>
    </TableContainer>
  );

  const noResultContent = (
    <div style={{textAlign: 'center'}}>
      <br/>回答がありません
    </div>
  );

  return (
    <GenericTemplate title='診断回答確認'>
      <br/><div style={{fontSize: '18px'}}>ユーザ選択</div>

      <FormControl style={{minWidth: '300px'}}>
        <Select
          defaultValue=''
          onChange={e => setUserId(userNameToId[e.target.value as string])}>
          {userData.map((data: any, index: number) => (
            <MenuItem value={data.name} key={index}>{data.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant='outlined'
        onClick={() => getTelemetry()}
        style={{marginLeft: '18px'}}>
        表示
      </Button>

      <Button
        id='download'
        slot='end'
        variant='outlined'
        onClick={() => {
          setCsvUserData('');
          createCsvData();
        }}
        style={{marginLeft: '18px'}}>
        一括DL
      </Button>

      {diagnosisResult.length > 0 && diagnosisResultTable}
      <br/>
      {telemetry.length > 0 && resultTable}

      {noResult && noResultContent}

    </GenericTemplate>
  );
};

export default Diagnosis;