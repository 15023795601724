import { loginApi } from '../api/Api';

export const isLoggedIn = async () => {
    const value = localStorage.getItem('isLoggedIn');
    return value === 'true';
};

export const Login = async (username: string, password: string, props: any) => {
    const loginResult = loginApi(username, password).then((result: any) => {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('token', result.data.token);
        localStorage.setItem('refreshToken', result.data.refreshToken);
        props.history.push('/');
    })
    return loginResult;
};

export const logout = async (props: any) => {
    isLoggedIn().then(state => {
        if (state) {
            localStorage.setItem('isLoggedIn', 'false');
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            props.push('/login');
        } 
    })
};
