import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Modal, TextField } from '@material-ui/core';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AddCircleOutlineOutlined, Delete, Edit } from '@material-ui/icons'

import GenericTemplate from "../templates/GenericTemplate";
import { getApi, postApi, execRefresh, deleteApi } from "../tools/api/Api";

const Information: React.FC = (props: any) => {
  const [token, setToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');

  const [info, setInfo] = useState({
    title: '', contents: '', link: '', start: '', end: '', priority: ''
  });
  const [infoData, setInfoData] = useState<any>([]);
  const [selectedId, setSelectedId] = useState<any[]>([]);
  const [addInfoWindow, setAddInfoWindow] = useState(false);
  const [deleteInfoWindow, setDeleteInfoWindow] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [update, setUpdate] = useState(true);

  const changeTokenState = (token: string, refreshToken: string, func?: any) => {
    setToken(token);
    setRefreshToken(refreshToken);
    if (func) func(token) 
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) setToken(token);
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) setRefreshToken(refreshToken);
  }, []);

  useEffect(() => {
    if (!(token && refreshToken && update)) {
      return;
    }

    getApi('tenant/assets?type=information&limit=1', token)
    .then((result: any) => {
      getApi(`plugins/telemetry/ASSET/${result.data.data[0].id.id}/values/attributes`, token)
      .then((result: any) => {
        const infoData = result.data
        .sort((a: any, b: any) => { return a.lastUpdateTs - b.lastUpdateTs })
        .map((data: any) => { return data.value })
        infoData.map((data: any, index: number) => { return data.id = index + 1 })
        setInfoData(infoData);
        setUpdate(false);
      })
    })
    .catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            execRefresh(refreshToken, changeTokenState, props);
            break
        };
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, refreshToken, update]);

  const postInfo = (refreshedToken?: string) => {
    setSelectedId([]);
    getApi('tenant/assets?type=information&limit=1', token)
    .then((result: any) => {
      postApi(`plugins/telemetry/ASSET/${result.data.data[0].id.id}/SERVER_SCOPE`, refreshedToken || token, {
        [info.title]: {
          title: info.title,
          contents: info.contents,
          link: info.link,
          start: info.start,
          end: info.end,
          priority: info.priority
        }
      })
      setUpdate(true)
    })
    .catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            execRefresh(refreshToken, changeTokenState, props, postInfo);
            break;
        };
      }
    })
  };

  const deleteInfo = (refreshedToken?: string) => {
    setSelectedId([]);
    getApi('tenant/assets?type=information&limit=1', token)
    .then((result: any) => {
      const deleteTitle = selectedId.map(id => { return infoData[id - 1]['title'] })
      deleteApi(`plugins/telemetry/ASSET/${result.data.data[0].id.id}/SERVER_SCOPE?keys=${deleteTitle.join(',')}`, refreshedToken || token)
      setUpdate(true)
    })
    .catch(err => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            execRefresh(refreshToken, changeTokenState, props, deleteInfo);
            break;
        };
      }
    })
  };

  const infoModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const addInfoModal = (
    <Box sx={infoModalStyle}>
      <h2>お知らせ 作成</h2>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField fullWidth required label='タイトル' variant="outlined"
            error={inputError && info.title === ''} helperText={inputError && info.title === '' && 'タイトルは必須項目です'}
            value={info.title} onChange={e => setInfo({...info, title: e.target.value})}/>        
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField fullWidth label='内容' variant="outlined" value={info.contents} onChange={e => setInfo({...info, contents: e.target.value})} />        
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField fullWidth label='リンク' variant="outlined" value={info.link} onChange={e => setInfo({...info, link: e.target.value})} />        
        </Grid>
        <Grid item xs={12} md={12} style={{display: 'flex', alignItems: 'center'}}>
          <TextField
            label='期間（開始）'
            type='date'
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={info.start}
            onChange={e => setInfo({...info, start: e.target.value})}/>
            　～　
          <TextField
            label='期間（終了）'
            type='date'
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={info.end}
            onChange={e => setInfo({...info, end: e.target.value})} />
          </Grid>
        <Grid item xs={12} md={6}>
          <TextField fullWidth label='優先度' type='number' variant="outlined" value={info.priority} onChange={e => setInfo({...info, priority: e.target.value})} />        
        </Grid>

        <Grid item xs={12} md={12} style={{textAlign: 'end'}}>
          <Button
            variant='outlined'
            onClick={() => setAddInfoWindow(false)}
            style={{width: '20%'}}>
            キャンセル
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              if (info.title === '') {
                setInputError(true);
              } else {
                setAddInfoWindow(false);
                postInfo();
              }
            }}
            style={{width: '20%', marginLeft: '24px'}}>
            登録
          </Button>        
        </Grid>
      </Grid>
    </Box>
  )

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'タイトル', width: 200 },
    { field: 'contents', headerName: '内容', width: 200 },
    { field: 'link', headerName: 'リンク', width: 200 },
    { field: 'start', headerName: '開始', type: 'date', width: 150 },
    { field: 'end', headerName: '終了', type: 'date', width: 150 },
    { field: 'priority', headerName: '優先度', width: 150 },
  ];

  return (
    <GenericTemplate title='お知らせ一覧'>
    
    <div style={{textAlign: 'end'}}>
      {selectedId.length === 1 &&
        <span>
          <IconButton onClick={() => {
            setInfo({
              title: infoData[selectedId[0] - 1]['title'],
              contents: infoData[selectedId[0] - 1]['contents'],
              link: infoData[selectedId[0] - 1]['link'],
              start: infoData[selectedId[0] - 1]['start'],
              end: infoData[selectedId[0] - 1]['end'],
              priority: infoData[selectedId[0] - 1]['priority']
            });
            setAddInfoWindow(true);
          }}>
            <Edit />
          </IconButton>
        </span>
      }
      {selectedId.length > 0 &&
        <span>
          <IconButton onClick={() => setDeleteInfoWindow(true)}>
            <Delete />
          </IconButton>
        </span>
      }
      <IconButton onClick={() => {
          setInputError(false);
          setInfo({
            title: '', contents: '', link: '', start: '', end: '', priority: ''
          });
          setAddInfoWindow(true);
        }}>
        <AddCircleOutlineOutlined />
      </IconButton>
    </div>

    <Dialog open={deleteInfoWindow} onClose={() => setDeleteInfoWindow(false)}>
      <DialogTitle>
        以下のお知らせを削除しますか？
      </DialogTitle>
      <DialogContent>
        {selectedId && selectedId.map(data => (
          <div key={data}>
            {infoData[data - 1]['title']}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteInfoWindow(false)}>キャンセル</Button>
        <Button
          onClick={() => {
            setDeleteInfoWindow(false);
            deleteInfo();
          }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>

    <Modal open={addInfoWindow} children={addInfoModal} onClose={() => setAddInfoWindow(false)}></Modal>

    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={infoData}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        onSelectionModelChange={id => setSelectedId(id)}
        selectionModel={selectedId}
      />
    </div>

    </GenericTemplate>
  );
};

export default Information;