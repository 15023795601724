import React, { useState } from "react";
import { Box, Button, Grid, TextField } from '@material-ui/core'

import { Login } from '../tools/auth/User';
import './Login.css'

const LoginPage: React.FC = (props: any) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);

  const login = (e: React.FormEvent) => {
    e.preventDefault();
    Login(username, password, props)
    .catch(() => setLoginError(true))
  };

  const loginBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Box sx={loginBoxStyle}>
      <form onSubmit={login}>
        <Grid item container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={8} md={8}>
            <TextField fullWidth label='ユーザー名' type='text' onChange={e => setUsername(e.target.value)}></TextField>
          </Grid>
          <Grid item xs={8} md={8}>
            <TextField fullWidth label='パスワード' type='password' onChange={e => setPassword(e.target.value)}></TextField>
          </Grid>
          <Grid item xs={8} md={8}>
            {loginError &&
              <div style={{textAlign: 'center', color: 'red'}}>
                ユーザー名かパスワードが違います
              </div>
            }
          </Grid>
          <Grid item xs={8} md={8}>
            <Button fullWidth type='submit' variant='outlined'>ログイン</Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default LoginPage;