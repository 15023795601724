import axios from 'axios';
import axiosRetry from 'axios-retry';

import { logout } from '../auth/User';

axiosRetry(axios, { retries: 3 });

const BaseUrl = process.env.REACT_APP_TB_URL + 'api/';

export const loginApi = (username: string, password: string) => {
    const loginResult = axios.post(BaseUrl + 'auth/login', {
      "username": username,
      "password": password
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    return loginResult;
  };

export const authUserApi = (token: string) => {
    const authUserResult = axios.get(BaseUrl + 'auth/user', {
      headers: {
        'Accept': 'application/json',
        'X-Authorization': 'Bearer ' + token
      }
    });
    return authUserResult;
}

export const getApi = (apiUrl: string, token: string) => {
    const getResult = axios.get(BaseUrl + apiUrl, {
      headers: {
        'Accept': 'application/json',
        'X-Authorization': 'Bearer ' + token
      }
    });
    return getResult;
};
  
export const postApi = (apiUrl: string, token: string, body: any) => {
    const postResult = axios.post(BaseUrl + apiUrl, body, {
      headers: {
        'Accept': 'application/json',
        'Content': 'application/json',
        'X-Authorization': 'Bearer ' + token
      }
    });
    return postResult;
};

export const deleteApi = (apiUrl: string, token: string) => {
    const deleteResult = axios.delete(BaseUrl + apiUrl, {
      headers: {
        'Accept': 'application/json',
        'X-Authorization': 'Bearer ' + token
      }
    });
    return deleteResult;
};

const refreshApi = (refreshToken: string) => {
    const refreshResult = axios.post(BaseUrl + 'auth/token', {
      "refreshToken": refreshToken
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    return refreshResult;
};

export const execRefresh = async (refreshToken: string, changeTokenState: any, props: any, func?: any) => {
    refreshApi(refreshToken)
    .then((result: any) => {
        const token = result.data.token
        const refreshToken = result.data.refreshToken
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        if (func) {
            changeTokenState(token, refreshToken, func)
        } else {
            changeTokenState(token, refreshToken)
        }
    })
    .catch(err => {
        switch (err.response.status) {
            case 401:
                logout(props)
                break;
        }
    })
};