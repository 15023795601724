import { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { isLoggedIn } from './User';

const Auth = (props: any) => {
    const [state, setState] = useState<boolean>();
    
    isLoggedIn().then(state => setState(state));

    //initial state: undefined
    if (state === undefined) {
        return ('loading');
    } else {
        //loggedIn: true => to home page , not loggedIn: false => to login page
        return (state ? props.children : <Redirect to="/login" />);
    };
};

export default Auth;