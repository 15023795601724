import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import Login from './components/pages/Login';
import Auth from './components/tools/auth/Auth';
import Check from "./components/pages/Check";
import Diagnosis from "./components/pages/Diagnosis";
import Information from './components/pages/Information';

import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
          <Auth>
            <Switch>
              <Route exact path="/check" component={Check} />
              <Route exact path="/diagnosis" component={Diagnosis} />
              <Route exact path="/information" component={Information} />
              <Redirect exact from="/" to="/check" />
            </Switch>
          </Auth>
      </Switch>
    </Router>
  );
}

export default App;
